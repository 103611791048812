<nav aria-label="Page Navigation">
    <div *ngIf="pageInfo && pageInfo.totalPages! > 1">
        <ul class="pagination">
            <li class="page-item">
                <a class="page-link"
                   href="javascript:void(0)"
                   aria-label="Previous"
                   title="Previous"
                   (click)="prevPage()"
                   [attr.disabled]="pageInfo.pageNumber! <= 1">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>

            <li class="page-item" *ngFor="let item of visiblePages" [class.active]="pageInfo.pageNumber == item">
                <a class="page-link"
                   href="javascript:void(0)"
                   (click)="selectPage(item)">
                    {{item}}
                </a>
            </li>

            <li class="page-item">
                <a class="page-link"
                   href="javascript:void(0)"
                   aria-label="Next"
                   title="Next"
                   (click)="nextPage()"
                   [attr.disabled]="pageInfo.pageNumber! >= pageInfo.totalPages!">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </div>
</nav>