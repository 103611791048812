import { Component, Input } from "@angular/core";

@Component({
    selector: 'dprep-dropdown-option',
    templateUrl: './dropdown-option.component.html'
})
export class DropdownOptionComponent {
    @Input()
    public routerLink: any;

    @Input()
    public queryParams: any;

    @Input()
    public state: any;
}
