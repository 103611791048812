import { Component } from "@angular/core";
import { Person } from "../../models/persons/person";
import { ClientService } from "../../services/persons/client.service";
import { ModalReference, } from "@developer-partners/ngx-modal-dialog";
import { TableViewModel } from "../../models/table-view-model";
import { ColumnType } from "../../components/sorting-filtering/query-view-model";
import { TableColumn } from "../../components/table/table.component";
import { Case } from "../../models/cases/case";

@Component({
    templateUrl: './client-lookup.component.html',
})

export class ClientLookupComponent {
    public clients: Person[] = [];
    public case: Case = {};
    public isLoading: boolean = true;
    public table: TableViewModel<Person> = {
        columns: this.getColumns(),
        query: {}
    };

    constructor(
        private readonly _clientService: ClientService,
        private readonly _modalReference: ModalReference<Person>
    ) {
    }

    public async ngOnInit(): Promise<void> {
        await this.getClients();
    }

    public async getClients(): Promise<void> {
        try {
            this.table.isLoading = true;
            this.table.items = await this._clientService.getAll(this.table.items?.pageInfo || {}, this.table.query);
        } finally {
            this.table.isLoading = false;
        }
    }

    private getColumns(): TableColumn<Person>[] {
        return [
            {
                field: 'firstName',
                caption: 'First Name',
                type: ColumnType.text
            },
            {
                field: 'lastName',
                caption: 'Last Name',
                type: ColumnType.text
            },
            {
                field: 'primaryEmail.text',
                caption: 'Primary Email',
                type: ColumnType.text
            },
            {
                field: 'gender',
                caption: 'Gender',
                type: ColumnType.other,
                options: [
                    {
                        id: '1',
                        value: 'Unspecified'
                    },
                    {
                        id: '2',
                        value: 'Male'
                    },
                    {
                        id: '3',
                        value: 'Female'
                    }
                ]
            },
            {
                field: 'addressLine1',
                caption: 'Address Line 1',
                type: ColumnType.text
            },
            {
                field: 'addressLine1',
                caption: 'Address Line 2',
                type: ColumnType.text
            },
            {
                field: 'city',
                caption: 'City',
                type: ColumnType.text
            },
            {
                field: 'state.name',
                caption: 'State',
                type: ColumnType.other
            },
            {
                field: 'postalCode',
                caption: 'Postal Code',
                type: ColumnType.text
            }
        ];
    }

    public selectClient(client: Person): void {
        this._modalReference.closeSuccess(client);
    }
}