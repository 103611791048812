import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlResolverService } from "../helpers/url-resolver.service";
import { QueryHelperService } from "../helpers/query-helper.service";
import { PageInfo } from "../../models/page-info";
import { PagedData } from "../../models/paged-data";
import { Person } from "../../models/persons/person";
import { Query } from "../../models/query";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    constructor(
        private readonly _http: HttpClient,
        private readonly _urlResolver: UrlResolverService,
        private readonly _queryHelper: QueryHelperService
    ) {

    }

    public getAll(pageInfo: PageInfo, query: Query): Promise<PagedData<Person>> {
        const url = this._urlResolver.resolveUrl('api/clients');

        const observable = this._http.get<PagedData<Person>>(url, {
            params: this._queryHelper.buildQuery(pageInfo, query)
        });

        return lastValueFrom(observable);
    }

    public get(id: number): Promise<Person> {
        const url = this._urlResolver.resolveUrl(`api/clients/${id}`);
        const observable = this._http.get<Person>(url);

        return lastValueFrom(observable);
    }

    public add(client: Person): Promise<Person> {
        const url = this._urlResolver.resolveUrl('api/clients');
        const observable = this._http.post<Person>(url, client);

        return lastValueFrom(observable);
    }

    public update(client: Person): Promise<Person> {
        const url = this._urlResolver.resolveUrl(`api/clients/${client.id}`);
        const observable = this._http.put<Person>(url, client);

        return lastValueFrom(observable);
    }

    public delete(id: number): Promise<Person> {
        const url = this._urlResolver.resolveUrl(`api/clients/${id}`);
        const observable = this._http.delete<Person>(url);

        return lastValueFrom(observable);
    }
}