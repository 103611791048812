<ng-template #actionsTemplate let-rowData="rowData">
    <dprep-dropdown title="Actions" iconCss="fa-solid fa-ellipsis-vertical" (click)="$event.stopPropagation()">
        <dprep-dropdown-option *ngIf="canViewDetails" (click)="onViewDetails(rowData)">
            <i class="fa-solid fa-eye"></i>
            Details
        </dprep-dropdown-option>
        <dprep-dropdown-option *ngIf="canEdit" (click)="onEdit(rowData)">
            <i class="fa-solid fa-pencil"></i>
            Edit
        </dprep-dropdown-option>
        <dprep-dropdown-option *ngIf="canDelete && !showSoftDeleted" (click)="onDelete(rowData)">
            <i class="fa-solid fa-trash"></i>
            Delete
        </dprep-dropdown-option>
        <dprep-dropdown-option *ngIf="canDelete && showSoftDeleted" (click)="onReactivate(rowData)">
            <i class="fa-solid fa-rotate-right"></i>
            Reactivate
        </dprep-dropdown-option>

        <ng-container *ngIf="customActions">
            <dprep-dropdown-option *ngFor="let action of customActions" (click)="customActionClicked(action, rowData)">
                <i [attr.class]="action.iconClass"></i>
                {{action.text}}
            </dprep-dropdown-option>
        </ng-container>
    </dprep-dropdown>
</ng-template>

<div>
    <div *ngIf="freeTextSearch">
        <form (ngSubmit)="performSearch()">
            <div class="input-group">
                <input type="text" class="form-control" name="freeTextSearch" [(ngModel)]="freeTextSearch.text"
                       [attr.placeholder]="freeTextSearch.placeholder" [attr.aria-label]="freeTextSearch.placeholder"
                       [attr.aria-describedby]="searchId">
                <button class="btn btn-secondary" type="submit" [attr.id]="searchId" title="Click to Search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </button>
                <button *ngIf="freeTextSearch.text" class="btn btn-secondary" type="button"
                        [attr.id]="resetSearchId" title="Click to Reset Search" (click)="resetFreeTextSearch()">
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
        </form>
    </div>

    <div class="d-flex justify-content-end border-bottom">
        <dprep-sorting-filtering *ngIf="query" [(query)]="query" (queryChange)="onQueryChange($event)"
                                 (queryReset)="onQueryReset()" [columns]="queryColumns!">
        </dprep-sorting-filtering>

        <button *ngIf="canHaveSoftDelete && !showSoftDeleted" type="button" class="btn" title="Show Archived Records"
                aria-label="Show Archived Records" (click)="showSoftDelete()">
            <i class="fa-solid fa-box-archive"></i>
        </button>

        <button *ngIf="canHaveSoftDelete && showSoftDeleted" type="button" class="btn" title="Show Active Records"
                aria-label="Show Active Records" (click)="showSoftDelete()">
            <i class="fa-solid fa-check-double"></i>
        </button>

        <button *ngIf="canAdd" type="button" class="btn" title="Add New Record" aria-label="Add New Record"
                (click)="onAdd()">
            <i class="fa-solid fa-circle-plus"></i>
            Add New
        </button>
    </div>

    <div class="table-responsive">
        <table class="table" [class.table-hover]="canSelect">
            <thead>
                <tr>
                    <ng-container *ngFor="let column of columns">
                        <th *ngIf="!column.isTableColumnExcluded" [hidden]="column.isInvisible" [class]="column.size"
                            class="text-nowrap bg-light text-secondary" role="button" (click)="sortColumn(column)">
                            {{column.caption}}

                            <span *ngIf="query?.sort && query?.sort![column.field]">
                                <i *ngIf="query?.sort![column.field] == 'Asc'" class="fa-solid fa-arrow-up"></i>
                                <i *ngIf="query?.sort![column.field] == 'Desc'" class="fa-solid fa-arrow-down"></i>
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let row of data" [attr.role]="canSelect ? 'button' : null" (click)="onSelect(row)">
                    <ng-container *ngFor="let column of columns">
                        <td *ngIf="!column.isTableColumnExcluded" [hidden]="column.isInvisible">
                            <dprep-table-cell *ngIf="!column.cellTemplate" [column]="column" [rowData]="row"
                                              [componentType]="column.cellComponent!">
                            </dprep-table-cell>

                            <ng-container *ngIf="column.cellTemplate">
                                <ng-container
                                              *ngTemplateOutlet="column.cellTemplate; context: { rowData: row, column: column }">
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-flex justify-content-center">
        <dprep-pagination *ngIf="pageInfo" [(pageInfo)]="pageInfo" (pageInfoChange)="pageInfoChange.emit($event)">
        </dprep-pagination>
    </div>
</div>