<div *ngIf="isViewReady">
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link"
               role="tab"
               [class.active]="selectedTab == 'Sorting'"
               href="javascript:void(0)"
               (click)="selectTab('Sorting')">
                Sorting
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link"
               role="tab"
               [class.active]="selectedTab == 'Filtering'"
               href="javascript:void(0)"
               (click)="selectTab('Filtering')">
                Filtering
            </a>
        </li>
    </ul>

    <form #queryForm="ngForm" (ngSubmit)="submitData()">
        <div class="tab-content">
            <div class="tab-pane p-3"
                 role="tabpanel"
                 [class.active]="selectedTab == 'Sorting'">

                <p>
                    Please click the Add Sorting button below to add sorting criteria.
                </p>
                <button type="button"
                        class="btn btn-primary"
                        (click)="addSortColumn()">
                    Add Sorting
                </button>

                <div class="mt-3 container-fluid">
                    <div *ngFor="let column of sortColumns; let i = index" class="row border p-1 mb-2">
                        <div class="col-md-5">
                            <div class="mb-3">
                                <dprep-input id="sort-column-{{i}}"
                                             name="sortColumn{{i}}"
                                             type="select"
                                             label="Column"
                                             [required]="true"
                                             [(ngModel)]="column.field"
                                             [form]="queryForm">
                                    <option *ngFor="let option of sortColumnOptions"
                                            [value]="option.id"
                                            [disabled]="option.disabled">
                                        {{option.value}}
                                    <option>
                                </dprep-input>
                            </div>
                        </div>

                        <div class="col-md-5">
                            <div class="mb-3">
                                <dprep-input id="sort-direction-{{i}}"
                                             name="sortDirection{{i}}"
                                             type="select"
                                             label="Direction"
                                             [required]="true"
                                             [(ngModel)]="column.value"
                                             [form]="queryForm">
                                    <option *ngFor="let option of sortDirectionOptions" [value]="option.id">
                                        {{option.value}}
                                    </option>
                                </dprep-input>
                            </div>
                        </div>

                        <div class="col-md-2 dprep-remove-sort-container">
                            <button class="btn btn-danger"
                                    title="Delete"
                                    aria-label="Delete"
                                    (click)="removeSortColumn(column)">
                                <i class="fa-solid fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane p-3"
                 role="tabpanel"
                 [class.active]="selectedTab == 'Filtering'">
                <p>
                    Please click the Add Filtering button below to add filtering criteria
                </p>

                <button type="button"
                        class="btn btn-primary mb-3"
                        (click)="addFilter()">
                    Add Filtering
                </button>

                <div *ngFor="let item of model.query.filter; let i  = index">
                    <dprep-filter-input *ngIf="!item.isHidden"
                                        [model]="item"
                                        [columns]="filterColumnOptions"
                                        [queryForm]="queryForm"
                                        [index]="i"
                                        (onDelete)="removeFilter(item)">
                    </dprep-filter-input>
                </div>
            </div>
        </div>

        <div class="text-center">
            <dprep-validation-summary [form]="queryForm"></dprep-validation-summary>

            <button type="submit" class="btn btn-primary">
                Submit
            </button>

            <button *ngIf="canReset" type="button" class="btn btn-secondary ms-2" (click)="reset()">
                Reset
            </button>

            <button type="button" class="btn btn-secondary ms-2" (click)="cancel()">
                Cancel
            </button>
        </div>
    </form>
</div>