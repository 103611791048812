<div>
    <dp-loading-spinner *ngIf="table.isLoading" />

    <dprep-table *ngIf="table.items"
                 [columns]="table.columns!"
                 [data]="table.items.data"
                 [canSelect]="true"
                 (select)="selectClient($event)"
                 [(pageInfo)]="table.items.pageInfo"
                 (pageInfoChange)="getClients()"
                 [(query)]="table.query"
                 (queryChange)="getClients()" />
</div>