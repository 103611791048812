import { Query } from "../../models/query";

export interface QueryViewModel {
    query: Query;
    columns: QueryColumn[];
    wasReset?: boolean;
}

export interface QueryColumn {
    id?: string;
    field: string;
    caption: string;
    type: ColumnType;
    options?: QueryColumnOption[];
}

export enum ColumnType {
    text = 'text',
    number = 'number',
    date = 'date',
    other = 'other'
}

export interface QueryColumnOption {
    id: string;
    value: string;
}
