import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
    selector: 'dprep-back-button',
    templateUrl: './back-button.component.html'
})
export class BackButtonComponent {
    @Input()
    public backNavigation?: string[]

    @Input()
    public backText: string = 'Back';

    constructor(private readonly _location: Location) {

    }

    public backClick(): void {
        if (!this.backNavigation?.length) {
            this._location.back();
        }
    }
}