import { Component, Input } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
    selector: 'dprep-validation-field',
    templateUrl: './validation-field.component.html'
})
export class ValidationFieldComponent {
    @Input()
    public form: NgForm = null!;

    @Input()
    public fieldName: string = null!;

    @Input()
    public fieldLabel: string = null!;
}
