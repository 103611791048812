import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ModalModule } from "@developer-partners/ngx-modal-dialog";
import { SortingFilteringComponent } from "./sorting-filtering/sorting-filtering.component";
import { TableCellComponent } from "./table/table-cells/table-cell.component";
import { TableComponent } from "./table/table.component";
import { SortingFilteringModalComponent } from "./sorting-filtering/sorting-filtering-modal.component";
import { FilterInputComponent } from "./sorting-filtering/filter-input.component";
import { LoadingSpinnerModule } from "@developer-partners/ngx-loading-spinner";
import { ValidationFieldComponent } from "./validation/validation-field/validation-field.component";
import { ValidationSummaryComponent } from "./validation/validation-summary/validation-summary.component";
import { InputComponent } from "./input/input.component";
import { OptionDirective } from "./input/option.directive";
import { FormsModule } from "@angular/forms";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { DropdownOptionComponent } from "./dropdown/dropdown-option.component";
import { RouterModule } from "@angular/router";
import { PaginationComponent } from "./pagination/pagination.component";
import { ConfirmModalComponent } from "./modals/confirm-modal.component";
import { SectionComponent } from "./section/section.component";
import { BackButtonComponent } from "./back-button/back-button.component";
import { LookupFieldComponent } from "./lookup-field/lookup-field.component";
import { ClientLookupComponent } from "../lookup/clients/client-lookup.component";
import { MessageModalComponent } from "./modals/message-modal.component";

@NgModule({
    declarations: [
        PaginationComponent,
        SortingFilteringComponent,
        TableCellComponent,
        TableComponent,
        SortingFilteringComponent,
        SortingFilteringModalComponent,
        FilterInputComponent,
        InputComponent,
        ValidationFieldComponent,
        ValidationSummaryComponent,
        OptionDirective,
        DropdownComponent,
        DropdownOptionComponent,
        ConfirmModalComponent,
        MessageModalComponent,
        SectionComponent,
        BackButtonComponent,
        LookupFieldComponent,
        ClientLookupComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ModalModule,
        LoadingSpinnerModule
    ],
    exports: [
        PaginationComponent,
        TableComponent,
        ModalModule,
        LoadingSpinnerModule,
        ValidationFieldComponent,
        ValidationSummaryComponent,
        InputComponent,
        OptionDirective,
        DropdownComponent,
        DropdownOptionComponent,
        SectionComponent,
        BackButtonComponent,
        LookupFieldComponent,
        ClientLookupComponent
    ]
})
export class SharedComponentsModule {

}