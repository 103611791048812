import { Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
    selector: 'dprep-validation-summary',
    templateUrl: './validation-summary.component.html'
})
export class ValidationSummaryComponent implements OnChanges {
    private _errorCount: number = 0;

    @Input()
    public form: NgForm = null!;

    @Input()
    public validationErrors: string[] = [];

    @ViewChild('container')
    public element: ElementRef<HTMLElement> = null!;

    public ngOnChanges(): void {
        if (this._errorCount !== this.validationErrors.length) {
            this.element.nativeElement.scrollIntoView({
                block: 'center',
                inline: 'start',
                behavior: 'smooth'
            });

            this._errorCount = this.validationErrors.length;
        }
    }
}
