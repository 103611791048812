<div>
    <label *ngIf="label" [attr.for]="id" class="form-label">
        {{label}}
        <span *ngIf="required" class="text-danger">*</span>
    </label>
    <div class="input-group">
        <input type="text"
               class="form-control"
               [attr.id]="id"
               [attr.name]="name"
               [(ngModel)]="value"
               [required]="required"
               readonly
               [attr.placeholder]="placeholder"
               [attr.aria-label]="placeholder"
               [attr.aria-describedby]="buttonId"
               (blur)="onTouched($event)"
               (change)="onChanged()"
               (click)="inputClicked()">
        <button *ngIf="canSearch && !disabled && !readonly"
                class="btn btn-secondary pt-0 pb-0"
                type="button"
                [attr.id]="buttonId"
                title="Click to Search"
                (click)="search.emit()">
            <i class="fa-solid fa-magnifying-glass"></i>
        </button>
        <button *ngIf="canReset"
                class="btn btn-secondary pt-0 pb-0"
                type="button"
                (click)="reset.emit()">
            <i class="fa-solid fa-xmark"></i>
        </button>
    </div>

    <dprep-validation-field *ngIf="form"
                            [form]="form"
                            [fieldName]="name"
                            [fieldLabel]="label">
    </dprep-validation-field>
</div>