<section class="border rounded p-3 shadow-sm">
    <div role="button" class="d-flex justify-content-between">
        <h2 class="h4">
            {{header}}
        </h2>

        <div>
            <button type="button" class="border btn btn-link px-2 py-1" (click)="isExpanded = !isExpanded">
                <i *ngIf="!isExpanded" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="isExpanded" class="fa-solid fa-chevron-up"></i>
            </button>
        </div>
    </div>

    <div *ngIf="isExpanded">
        <ng-content></ng-content>
    </div>
</section>