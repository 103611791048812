<div>
    <div>
        <p *ngFor="let message of messages">
            {{message}}
        </p>
    </div>

    <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-secondary me-2 w-100" (click)="cancel()">
            No
        </button>

        <button type="button" class="btn btn-primary w-100" (click)="submit()">
            Yes
        </button>
    </div>
</div>