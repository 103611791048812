import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: 'dprep-lookup-field',
    templateUrl: './lookup-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LookupFieldComponent),
            multi: true
        }
    ],
    styles: [
        `
        :host {
            display: block;
        }`
    ]
})
export class LookupFieldComponent implements ControlValueAccessor, OnInit {
    private static _counter = 0;

    private _onChanged?: (value: any) => void;
    private _onTouched?: (event: FocusEvent) => void;

    @Input()
    public id?: string;

    @Input()
    public name: string = null!;

    @Input()
    public required: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public readonly: boolean = false;

    @Input()
    public value?: string;

    @Input()
    public canSearch: boolean = false;

    @Input()
    public canReset: boolean = false;

    @Input()
    public placeholder?: string;

    @Input()
    public label: string = null!;

    @Input()
    public form?: NgForm;

    @Output()
    public search: EventEmitter<void> = new EventEmitter();

    @Output()
    public reset: EventEmitter<void> = new EventEmitter();

    public buttonId?: string;

    public writeValue(obj: any): void {
        this.value = obj;
    }

    public registerOnChange(fn: any): void {
        this._onChanged = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public ngOnInit(): void {
        LookupFieldComponent._counter = LookupFieldComponent._counter + 1;

        if (!this.id) {
            this.id = `input-${LookupFieldComponent._counter}`;
        }

        if (!this.name) {
            this.name = `input${LookupFieldComponent._counter}`;
        }

        if (!this.buttonId) {
            this.buttonId = `lookup-button-${LookupFieldComponent._counter}`;
        }
    }

    public onChanged(): void {
        if (this._onChanged) {
            this._onChanged(this.value);
        }
    }

    public onTouched(event: FocusEvent): void {
        if (this._onTouched) {
            this._onTouched(event);
        }
    }

    public inputClicked(): void {
        if (!this.value) {
            this.search.emit();
        }
    }
}